import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick } from 'vue';
import useTablePagination from '@/hooks/pagination';
import moment from 'moment';
import { selectRegisterByPagination, updateToRegisterApproval, registerApplyById } from '@/api/login';
import { QuestionCircleOutlined } from '@ant-design/icons-vue'; //表头

const columns = [{
  title: '账号类型',
  dataIndex: 'accountType',
  slots: {
    customRender: 'accountType'
  }
}, {
  title: '账号名称',
  dataIndex: 'userName'
}, {
  title: '账号属性',
  dataIndex: 'menuGenre',
  slots: {
    customRender: 'menuGenre'
  }
}, {
  title: '公司名称',
  dataIndex: 'companyName'
}, {
  title: '项目名称',
  dataIndex: 'belongProjectName'
}, {
  title: '申请时间',
  dataIndex: 'createTime',
  sorter: (a, b) => {
    let aTimeString = a.createTime;
    let bTimeString = b.createTime;
    let aTime = new Date(aTimeString).getTime();
    let bTime = new Date(bTimeString).getTime();
    return aTime - bTime;
  }
}, {
  title: '审核时间',
  dataIndex: 'foundTime'
}, {
  title: '审核状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  width: 250,
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    QuestionCircleOutlined
  },

  setup() {
    const formRef = ref();
    const formState = reactive({
      companyName: undefined,
      menuGenre: undefined,
      state: undefined,
      data: []
    }); //点击查询

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    }; //表格


    const tableData = ref([// {
      //   key: '1',
      //   userName: 'John',
      //   menuGenre: 1,
      //   companyName: 'Brown',
      //   belongProjectName: 32,
      //   createTime: '2022-01-01',
      //   foundTime: '2022-01-01',
      //   state: 0,
      // },
    ]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    }; //获取表格数据


    const getTableData = async () => {
      const {
        data
      } = formState;
      const [startTime, endTime] = data.map((item, index) => {
        if (index) {
          return item.endOf('day').format('YYYY-MM-DD');
        }

        return item.startOf('day').format('YYYY-MM-DD');
      });
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        menuGenre: formState.menuGenre ? formState.menuGenre : null,
        state: formState.state === undefined ? null : formState.state,
        startTime: startTime ? startTime : null,
        endTime: endTime ? endTime : null
      };
      loading.value = true;

      try {
        const res = await selectRegisterByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loading.value = false;
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    };

    const isDetail = ref(1);
    const registerDetail = reactive({
      userName: "",
      menuGenre: undefined,
      companyName: "",
      belongProjectName: "",
      applicantName: "",
      email: "",
      mobilePhone: "",
      createTime: "",
      pastTime: "",
      foundTime: "",
      state: undefined,
      initSpaceNum: 50,
      usageRate: 0,
      accountType: undefined
    });
    const menuVisible = ref(false);

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const registerDetailLoading = ref(false);
    const registerDetailLoading1 = ref(false); //审核提交

    const handleOk = async (id, val) => {
      if (val && !registerDetail.pastTime) {
        _message.error('到期时间不能为空！');

        return;
      }

      if (val && !registerDetail.initSpaceNum) {
        _message.error('存储空间不能为空！');

        return;
      }

      const obj = {
        applyId: id,
        pastTime: registerDetail.pastTime ? registerDetail.pastTime.startOf('day').format('YYYY-MM-DD') : null,
        state: val,
        initSpaceNum: val ? registerDetail.initSpaceNum : null,
        usageRate: val ? registerDetail.usageRate : null
      };

      if (val == 1) {
        registerDetailLoading.value = true;
      } else {
        registerDetailLoading1.value = true;
      }

      let res = await updateToRegisterApproval(obj);

      if (res.code === 200) {
        _message.success(res.message);

        if (val == 1) {
          registerDetailLoading.value = false;
        } else {
          registerDetailLoading1.value = false;
        }

        menuVisible.value = false;
        getTableData();
      } else {
        _message.error(res.message);

        if (val == 1) {
          registerDetailLoading.value = false;
        } else {
          registerDetailLoading1.value = false;
        }
      }

      if (val == 1) {
        registerDetailLoading.value = false;
      } else {
        registerDetailLoading1.value = false;
      }
    };

    const closeVisible = () => {
      menuVisible.value = false;
    };

    const toModification = (val, num) => {
      // console.log('toModification',val)
      isDetail.value = num;
      getDetailData(val.id);
    }; //获取详情


    const getDetailData = async id => {
      const res = await registerApplyById(id);

      if (res.code === 200) {
        Object.assign(registerDetail, res.data);
        menuVisible.value = true;
      } else {
        _message.error(res.message);

        menuVisible.value = false;
      }
    };

    onMounted(() => {
      getTableData();
    });
    return {
      formRef,
      formState,
      onSubmit,
      resetForm,
      paginationConfig,
      columns,
      tableData,
      loading,
      onPageChange,
      registerDetail,
      menuVisible,
      disabledDate,
      handleOk,
      closeVisible,
      toModification,
      isDetail,
      registerDetailLoading,
      registerDetailLoading1
    };
  }

});